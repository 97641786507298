<template>
  <div class="space-y-4">
    <article>
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Create Item Type Threshold',
            })
          "
          icon="file-document"
          text="Create Item Type Threshold"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="pt-8">
      <asom-server-table
        :key="`table` + tableKeyIndex"
        :url="url"
        :columns="[
          'inventoryTypeName',
          'inventoryGroup',
          'quantity',
          'createdBy',
          'lastModifiedBy',
          'action',
        ]"
      >
        <template v-slot:header_inventoryTypeName>Item Type Name</template>
        <template v-slot:header_quantity>Threshold Quantity</template>
        <template v-slot:inventoryTypeName="scopedSlots">
          <button
            @click="navigateToView(scopedSlots.rowData)"
            class="text-button underline font-semibold text-left"
          >
            {{ scopedSlots.data }}
          </button>
        </template>
        <template v-slot:action="scopedSlots">
          <div class="flex flex-col gap-1 w-48">
            <asom-button
              size="sm"
              outline
              text="Update"
              @click="updateClicked(scopedSlots.rowData)"
            />
            <asom-button
              size="sm"
              outline
              text="Remove"
              variant="error"
              @click="deleteClicked(scopedSlots.rowData)"
            />
          </div>
        </template>
      </asom-server-table>
    </div>
    <asom-modal
      title="Update Inventory Type Threshold"
      v-model="showUpdateModal"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="updateModalError"
        :error-message="updateModalError"
      />
      <asom-form-field
        class="col-span-2"
        label="Threshold Quantity"
        required
        :state="inputStates('formData.thresholdQuantity')"
        error="Threshold Quantity is required"
      >
        <asom-input-text
          type="number"
          v-model="formData.thresholdQuantity"
          :state="inputStates('formData.thresholdQuantity')"
        />
      </asom-form-field>
      <div class="flex flex-row-reverse pt-8">
        <div class="pl-4">
          <asom-button
            @click="onUpdateOrder"
            text="Update"
            :loading="isSubmittingUpdate"
          />
        </div>
        <div>
          <asom-button
            @click="toggleUpdateModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      title="Remove Item From The Station"
      v-model="showDeleteModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse">
        <div class="pl-4">
          <asom-button
            @click="onDeleteItem"
            text="OK"
            :loading="isSubmittingDelete"
          />
        </div>
        <div>
          <asom-button
            @click="toggleDeleteModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import inputStates from "@/mixins/inputStates";
import { required } from "@vuelidate/validators";
import {
  editInventoryTypeThreshold,
  deleteInventoryTypeThreshold,
} from "../../../../services/inventory.service";
import { GET_INVENTORY_TYPE_THRESHOLD_LIST } from "../../../../constants/apis";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      typeList: [],
      tableData: [],
      error: null,
      selectedItem: null,
      formData: {
        thresholdQuantity: null,
      },
      showUpdateModal: false,
      isSubmittingUpdate: false,
      updateModalError: null,
      showDeleteModal: false,
      isSubmittingDelete: false,
      deleteModalError: null,
      tableKeyIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
      canEditInventoryNotifications: "auth/canEditInventoryNotifications",
    }),
    url() {
      return GET_INVENTORY_TYPE_THRESHOLD_LIST 
        + `?stationId=${this.stationId}` 
        + `&lineId=${this.lineId}`
    },
  },
  validations() {
    return {
      formData: { thresholdQuantity: { required } },
    };
  },
  methods: {
    navigateToView(selectedItem) {
      this.$router.push({
        name: "View Item Type Threshold",
        params: selectedItem,
      });
    },
    updateClicked(data) {
      this.selectedItem = data;
      this.formData.thresholdQuantity = get(data, "quantity");
      this.toggleUpdateModal(true);
    },
    toggleUpdateModal(value) {
      this.updateModalError = null;
      this.showUpdateModal = value;
    },
    async onUpdateOrder() {
      this.updateModalError = null;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmittingUpdate = true;
        const result = await editInventoryTypeThreshold({
          inventoryTypeThresholdId: get(
            this.selectedItem,
            "inventoryTypeThresholdId"
          ),
          stationId: get(this.selectedItem, "stationId"),
          lineId: this.lineId,
          inventoryTypeId: get(this.selectedItem, "inventoryTypeId"),
          quantity: this.formData.thresholdQuantity,
        });
        if (result.success) {
          this.isSubmittingUpdate = false;
          this.toggleUpdateModal(false);
          this.tableKeyIndex++; // force reload data
        } else {
          this.isSubmittingUpdate = false;
          this.updateModalError = result.payload;
          this.toggleUpdateModal(false);
          this.$scrollTop();
        }
      } else {
        this.modalError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    deleteClicked(selectedItem) {
      this.selectedItem = selectedItem;
      this.toggleDeleteModal(true);
    },
    toggleDeleteModal(value) {
      this.showDeleteModal = value;
    },
    async onDeleteItem() {
      this.isSubmitting = true;
      const result = await deleteInventoryTypeThreshold({
        inventoryThresholdId: get(
          this.selectedItem,
          "inventoryTypeThresholdId"
        ),
      });
      if (result.success) {
        this.isSubmittingDelete = false;
        this.toggleDeleteModal(false);
        this.tableKeyIndex++; // force reload data
      } else {
        this.isSubmittingDelete = false;
        this.deleteModalError = result.payload;
        this.toggleDeleteModal(false);
        this.$scrollTop();
      }
    },
  },
};
</script>
